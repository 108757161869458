<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title"> {{ user.id ? "Edit" : "New" }} user </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <form @submit.prevent="saveUser" ref="form">
        <h5 class="fw-bolder mb-6">User Details</h5>
        <div class="d-flex flex-row row mb-12">
          <div class="d-flex flex-column col-6 mb-5">
            <label for="firstname">Firstname</label>
            <input
              type="text"
              class="form-control"
              id="firstname"
              v-model="user.firstname"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="lastname">Lastname</label>
            <input
              type="text"
              class="form-control"
              id="lastname"
              v-model="user.lastname"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="user.email"
              required
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="role">Role</label>
            <select v-model="user.role" class="form-select" required>
              <option v-for="(role) in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
            </select>
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label for="password">
              Password
              <a href="#" @click.prevent="generateRandomPassword">
                <small style="float: right;">Generate random password</small>
              </a>
            </label>
            <input
              type="text"
              class="form-control"
              id="password"
              v-model="user.password"
              :placeholder="Boolean(user.id) ? 'New password (optional)' : 'New password'"
              :required="!Boolean(user.id)"
            />
          </div>
          <div class="d-flex flex-column col-6 mb-5">
            <label>Doctor</label>
            <div class="form-check form-switch form-check-custom form-check-solid mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="isDoctor"
                v-model="user.isDoctor"
              />
              <label class="form-check-label" for="isDoctor">
                Is Doctor (if so, can be assigned patients in calendar events, and will be displayed on the clinic's page of Neoris website)
              </label>
            </div>
          </div>
        </div>

        <template v-if="user.isDoctor">
          <h5 class="fw-bolder mb-6">Doctor's Presentation (for Neoris website)</h5>
          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fs-6 fw-bold">
              Picture<br />
              <span class="text-muted">
                A professional picture of the doctor
              </span>
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-10">
                  <input
                    :disabled="isUploading"
                    @change="handleUserImageUpload($event.target.files[0])"
                    type="file"
                    class="form-control"
                    accept="image/jpeg,image/png"
                  />
                </div>
                <div class="col-2">
                  <img class="img-fluid" :src="user.presentationDraft.image
                    ? `${apiUrl}/doctors-images/${user.presentationDraft.image}`
                    : 'media/avatars/blank.png'">
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">
              Presentation<br />
              <span class="text-muted"> Max. 400 characters </span>
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <textarea
                    class="form-control"
                    v-model="user.presentationDraft.description"
                    maxlength="400"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">
              Experience/background<br />
              <span class="text-muted">
                E.g. diplomas and experience, max. 200 characters
              </span>
            </label>
            <div class="col-lg-8">
              <div class="row">
                <div class="fv-row">
                  <textarea
                    class="form-control"
                    v-model="user.presentationDraft.experience"
                    maxlength="200"
                    required
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-6">
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              <span class="required">Spoken languages</span>
            </label>
            <div class="col-lg-8 fv-row">
              <v-select
                v-model="user.presentationDraft.spokenLanguages"
                :options="iso6391languages"
                :reduce="(e) => e.code"
                label="name"
                multiple
              >
                <template #no-options>Search language...</template>
                <template #option="option">
                  <div class="d-center">{{ option.name }}</div>
                </template>
                <template #selected-option="option">
                  <div class="selected d-center">{{ option.name }}</div>
                </template>
              </v-select>
            </div>
          </div>
          
          <div v-if="!user.presentationDraft.approved" class="alert alert-warning mb-0" role="alert">
            ⚠️ Modifications made to the presentation are submitted for 
            review to the Neoris team before appearing on Neoris website.
          </div>
          <div v-else class="alert alert-success mb-0" role="alert">
            This is how your presentation appears on Neoris website.
            Modifications will be subject to review by Neoris team.
          </div>
        </template>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <a
        class="btn btn-lg btn-primary me-2"
        :disabled="isUploading"
        @click.prevent="onClickConfirm"
        >Confirm</a
      >
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import Iso6391languages from "@/core/constants/Iso-639-1-to-language";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import * as Yup from "yup";
import _ from "lodash";
import "vue-select/dist/vue-select.css";

export default {
  emits: ["confirm", "cancel"],
  data() {
    return {
      isUploading: false,
			iso6391languages: Iso6391languages,
      apiUrl: process.env.VUE_APP_API_URL,
      image: null, // Contains image for upload
      roles: [],
      user: {
        firstname: "",
        lastname: "",
        role: "",
        email: "",
        password: "",
        isDoctor: false,
        presentationDraft: {
          image: "",
          description: "",
          experience: "",
          spokenLanguages: [],
        },
      },
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      ApiService
        .get('/roles')
        .then(({ data }) => {
          this.roles = data.results;
        });
    },
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    generateRandomPassword() {
      this.user.password = Math.random().toString(36).substr(2, 10);
    },
		handleUserImageUpload(file) {
			this.isUploading = true;
			const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
			if (!allowedTypes.includes(file.type)) {
				alert("Only jpeg and png images are allowed");
				this.isUploading = false;
				return;
			}
			if (file.size > 2 * 1000000) { // Max. 2Mo
				alert("File too large, max. 2Mo");
				this.isUploading = false;
				return;
			}
			this.image = file;
			this.isUploading = false;
		},
    async saveUser() {
      if (this.user.id) {
        const config = {
          responseToast: {
            text: "User updated successfully.",
          },
        };
        ApiService.update("/users", this.user.id, this.user, config)
          .then(({ data }) => {
            console.log(data);
            // Once classic fields have been updated, handle image
            if (this.image) {
              const formData = new FormData();
              formData.append("image", this.image);
              ApiService
                .update("/users", this.user.id, formData)
                .then(() => {
                  this.image = null;
                  this.$emit("confirm", data);
                });
            } else {
              this.$emit("confirm", data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const config = {
          responseToast: {
            text: "User created successfully.",
          },
        };
        ApiService.post("/users", this.user, config)
          .then(({ data }) => {
            console.log(data);
            // Once classic fields have been created, handle image
            if (this.image) {
              const formData = new FormData();
              formData.append("image", this.image);
              ApiService
                .update("/users", data.id, formData)
                .then(() => {
                  this.image = null;
                  this.$emit("confirm", data);
                });
            } else {
              this.$emit("confirm", data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    processParams(existingUser) {
      if (existingUser && Object.keys(existingUser).length) {
        const clonedUser = _.cloneDeep(existingUser);
        delete clonedUser.presentation; // Keep presentationDraft only
        if (clonedUser.role && clonedUser.role.id) {
          clonedUser.role = clonedUser.role.id;
        }
        this.user = clonedUser;
      } else {
        this.user = {
          firstname: "",
          lastname: "",
          role: "",
          email: "",
          password: "",
          isDoctor: false,
          presentationDraft: {
            image: "",
            description: "",
            experience: "",
            spokenLanguages: [],
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select {
  z-index: 10;
}
</style>
<style lang="scss">
.v-select .vs__dropdown-toggle {
  padding: 2px;
  border-radius: 4px;
  box-shadow: none;
  min-width: 144px;
}

.vs__open-indicator {
  transform: scale(0.6) !important;
}
</style>